import { ChangeDetectionStrategy, Component, inject, model, ModelSignal } from '@angular/core';
import { MainWrapperComponent } from 'src/app/shared/components/main-wrapper/main-wrapper.component';
import { Content, ContentularService } from '@contentular/angular';
import { ReleaseNotes1ListItemModel, ReleaseNotes1Model } from '../../release-notes.model';
import { ReleaseNotesListItemComponent } from '../../components/release-notes-list-item/release-notes-list-item.component';

@Component({
    selector: 'app-release-notes-index',
    imports: [MainWrapperComponent, ReleaseNotesListItemComponent],
    template: `
        <app-main-wrapper>
            <div class="px-24 py-12 w-full max-w-[1200px] flex flex-col">
                <div class="flex flex-col">
                    <div class="text-2xl font-bold text-stone-900">Release Notes</div>
                    <div class="text-stone-500 text-sm font-medium mt-3 max-w-[800px]">
                        Here you can find the release notes for the latest version of our app. We update the app
                        regularly to improve the user experience and add new features.
                    </div>
                </div>

                <div class="mt-12 max-h-full overflow-auto relative h-full">
                    @for (item of contents(); track item._id) {
                        <app-release-notes-list-item [content]="item"></app-release-notes-list-item>
                    }
                </div>
            </div>
        </app-main-wrapper>
    `,
    styleUrl: './release-notes-index.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
})
export class ReleaseNotesIndexComponent {
    contents: ModelSignal<Content<ReleaseNotes1ListItemModel>[]> =
        model.required<Content<ReleaseNotes1ListItemModel>[]>();
    contentularService = inject(ContentularService);

    ngAfterViewInit() {
        this.contentularService.findBySlug('release-notes').subscribe(stories => {
            const contents = stories[0].contents[0].fields.releaseNotes as Content<ReleaseNotes1ListItemModel>[];
            this.contents.set(contents);
        });
    }
}
