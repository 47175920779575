import { ChangeDetectionStrategy, Component, input, Input } from '@angular/core';

@Component({
    selector: 'app-chip',
    template: `
        <div [class]="class">
            <ng-content></ng-content>
        </div>
    `,
    styleUrls: ['./chip.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [],
})
export class ChipComponent {
    color = input<'primary' | 'white' | 'blue' | 'green' | 'red' | 'beige' | 'orange' | 'purple' | 'transparent'>(
        'primary',
    );
    width = input<string>('0');
    outline = input(false);

    get class() {
        return `chip chip-${this.color()} px-2 py-0.5 rounded-full w-full ${this.outline() ? 'chip-outline' : ''}`;
    }
}
