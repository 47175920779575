import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { Content } from '@contentular/angular';
import { ChipComponent } from 'src/app/shared/components/chip/chip.component';
import { ReleaseNotes1ListItemModel } from '../../release-notes.model';

@Component({
    selector: 'app-release-notes-list-item',
    imports: [ChipComponent],
    template: `
        <div class="flex flex-col mt-10">
            <div class="text-stone-900">
                <h3 class="font-bold text-lg leading-none text-stone-700">Version {{ content().fields.version }}</h3>
                <span class="text-stone-500 text-xs font-medium">{{ content().fields.date }}</span>
            </div>
            @if (content().fields.added) {
                <div class="flex mt-3 mb-4">
                    <div class="w-[100px]">
                        <app-chip [outline]="true" color="green">Added</app-chip>
                    </div>
                    <div class="ml-8">
                        <div class="text-stone-500" [innerHTML]="content().fields.added"></div>
                    </div>
                </div>
            }
            @if (content().fields.fixed) {
                <div class="flex mt-3 mb-4">
                    <div class="w-[100px]">
                        <app-chip [outline]="true" color="orange">Fixed</app-chip>
                    </div>
                    <div class="ml-8">
                        <div class="text-stone-500" [innerHTML]="content().fields.fixed"></div>
                    </div>
                </div>
            }
            @if (content().fields.improved) {
                <div class="flex mt-3 mb-4">
                    <div class="w-[100px]">
                        <app-chip [outline]="true" color="purple">Improved</app-chip>
                    </div>
                    <div class="ml-8">
                        <div class="text-stone-500" [innerHTML]="content().fields.improved"></div>
                    </div>
                </div>
            }
            @if (content().fields.removed) {
                <div class="flex mt-3 mb-4">
                    <div class="w-[100px]">
                        <app-chip [outline]="true" color="red">Removed</app-chip>
                    </div>
                    <div class="ml-8">
                        <div class="text-stone-500" [innerHTML]="content().fields.removed"></div>
                    </div>
                </div>
            }
        </div>
    `,
    styleUrl: './release-notes-list-item.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
})
export class ReleaseNotesListItemComponent {
    content = input.required<Content<ReleaseNotes1ListItemModel>>();
}
