import * as i0 from '@angular/core';
import { InjectionToken, ViewContainerRef, Component, ChangeDetectionStrategy, Inject, ViewChild, Input, HostBinding, Directive, Injectable, NgModule, PLATFORM_ID } from '@angular/core';
import { CommonModule, isPlatformServer } from '@angular/common';
import * as i1$1 from '@angular/common/http';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { ReplaySubject, fromEventPattern, of } from 'rxjs';
import { filter, map, take, switchMap, shareReplay, tap, catchError } from 'rxjs/operators';
import * as i1 from '@angular/router';
import { NavigationEnd } from '@angular/router';
const _c0 = ["templateRef"];
function ContentularComponent_ng_template_0_Template(rf, ctx) {}
const _c1 = ["container"];
const CONTENTULAR_CONFIG = new InjectionToken('contentular.config');
class ContentularComponent {
  get renderFlowLess() {
    return this.flowless ? 'contents' : null;
  }
  set content(content) {
    if (!content) {
      return;
    }
    if (typeof this.config.componentMap[content.type] === 'undefined') {
      console.info(content.type, ' not found');
      return;
    }
    const viewContainerRef = this.templateRef;
    viewContainerRef.clear();
    this.componentRef = viewContainerRef.createComponent(this.config.componentMap[content.type]);
    if (typeof this.componentRef.instance.content?.set === 'function') {
      this.componentRef.instance.content.set(content);
    } else {
      this.componentRef.instance.content = content;
    }
    this.cdr.markForCheck();
  }
  constructor(config, cdr) {
    this.config = config;
    this.cdr = cdr;
    this.flowless = false;
  }
  /** @nocollapse */
  static {
    this.ɵfac = function ContentularComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || ContentularComponent)(i0.ɵɵdirectiveInject(CONTENTULAR_CONFIG), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef));
    };
  }
  /** @nocollapse */
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: ContentularComponent,
      selectors: [["contentular"]],
      viewQuery: function ContentularComponent_Query(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵviewQuery(_c0, 7, ViewContainerRef);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.templateRef = _t.first);
        }
      },
      hostVars: 2,
      hostBindings: function ContentularComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵstyleProp("display", ctx.renderFlowLess);
        }
      },
      inputs: {
        flowless: "flowless",
        content: "content"
      },
      standalone: false,
      decls: 2,
      vars: 0,
      consts: [["templateRef", ""]],
      template: function ContentularComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵtemplate(0, ContentularComponent_ng_template_0_Template, 0, 0, "ng-template", null, 0, i0.ɵɵtemplateRefExtractor);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ContentularComponent, [{
    type: Component,
    args: [{
      selector: 'contentular',
      template: `
        <ng-template #templateRef></ng-template>
    `,
      changeDetection: ChangeDetectionStrategy.OnPush
    }]
  }], () => [{
    type: undefined,
    decorators: [{
      type: Inject,
      args: [CONTENTULAR_CONFIG]
    }]
  }, {
    type: i0.ChangeDetectorRef
  }], {
    templateRef: [{
      type: ViewChild,
      args: ['templateRef', {
        read: ViewContainerRef,
        static: true
      }]
    }],
    flowless: [{
      type: Input
    }],
    renderFlowLess: [{
      type: HostBinding,
      args: ['style.display']
    }],
    content: [{
      type: Input
    }]
  });
})();
class EditorComponent {
  constructor(renderer) {
    this.renderer = renderer;
  }
  ngOnInit() {
    this.container.nativeElement.innerHTML = this.wrap;
    const textarea = this.renderer.createElement('textarea');
    this.container.nativeElement.children[0].appendChild(textarea);
    RedactorX(textarea, {
      control: true,
      context: true,
      toolbar: false,
      content: this.html
    });
  }
  /** @nocollapse */
  static {
    this.ɵfac = function EditorComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || EditorComponent)(i0.ɵɵdirectiveInject(i0.Renderer2));
    };
  }
  /** @nocollapse */
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: EditorComponent,
      selectors: [["lib-editor"]],
      viewQuery: function EditorComponent_Query(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵviewQuery(_c1, 7);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.container = _t.first);
        }
      },
      standalone: false,
      decls: 2,
      vars: 0,
      consts: [["container", ""]],
      template: function EditorComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelement(0, "div", null, 0);
        }
      },
      encapsulation: 2
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(EditorComponent, [{
    type: Component,
    args: [{
      selector: 'lib-editor',
      template: "<div #container></div>\n"
    }]
  }], () => [{
    type: i0.Renderer2
  }], {
    container: [{
      type: ViewChild,
      args: ['container', {
        static: true
      }]
    }]
  });
})();
class EditorDirective {
  // @HostBinding('attr.contenteditable') contentEditable = true;
  constructor(vcr, templateRef,
  // private renderer: Renderer2,
  componentFactoryResolver) {
    this.vcr = vcr;
    this.templateRef = templateRef;
    this.componentFactoryResolver = componentFactoryResolver;
  }
  ngOnInit() {
    // const textarea: HTMLTextAreaElement = this.renderer.createElement('textarea');
    // const html = this.vcr.element.nativeElement.innerHTML;
    const factory = this.componentFactoryResolver.resolveComponentFactory(EditorComponent);
    const embeddedView = this.vcr.createEmbeddedView(this.templateRef);
    const html = this.templateRef.elementRef.nativeElement.previousElementSibling;
    // html.innerHTML = this.appEditor;
    const wrap = document.createElement('div');
    wrap.appendChild(html.cloneNode(true));
    this.vcr.clear();
    const component = this.vcr.createComponent(factory);
    console.log(wrap.innerHTML);
    // component.instance.templateRef = this.templateRef;
    component.instance.html = this.appEditor;
    component.instance.wrap = wrap.innerHTML;
  }
  /** @nocollapse */
  static {
    this.ɵfac = function EditorDirective_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || EditorDirective)(i0.ɵɵdirectiveInject(i0.ViewContainerRef), i0.ɵɵdirectiveInject(i0.TemplateRef), i0.ɵɵdirectiveInject(i0.ComponentFactoryResolver));
    };
  }
  /** @nocollapse */
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: EditorDirective,
      selectors: [["", "appEditor", ""]],
      inputs: {
        appEditor: "appEditor"
      },
      standalone: false
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(EditorDirective, [{
    type: Directive,
    args: [{
      selector: '[appEditor]'
    }]
  }], () => [{
    type: i0.ViewContainerRef
  }, {
    type: i0.TemplateRef
  }, {
    type: i0.ComponentFactoryResolver
  }], {
    appEditor: [{
      type: Input
    }]
  });
})();
class LivePreviewService {
  constructor(router) {
    this.router = router;
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(event => {
      // console.log(window.parent);
      if (typeof window !== 'undefined' && window.parent !== window.top) {
        try {
          window.parent.postMessage(JSON.stringify({
            type: 'routed',
            payload: event
          }), 'https://app.contentular.io');
        } catch (e) {}
      }
    });
  }
  /** @nocollapse */
  static {
    this.ɵfac = function LivePreviewService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || LivePreviewService)(i0.ɵɵinject(i1.Router));
    };
  }
  /** @nocollapse */
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: LivePreviewService,
      factory: LivePreviewService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LivePreviewService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1.Router
  }], null);
})();
const ROOT_OPTIONS = new InjectionToken('ROOT_OPTIONS');
function contentularConfigFactory(options) {
  return {
    cachingStrategy: "network-only" /* ContentularCachingStrategy.networkOnly */,
    persistentCache: false,
    ...options
  };
}
class ContentularModule {
  static forRoot(userConfig) {
    return {
      ngModule: ContentularModule,
      providers: [{
        provide: ROOT_OPTIONS,
        useValue: userConfig
      }, {
        provide: CONTENTULAR_CONFIG,
        useFactory: contentularConfigFactory,
        deps: [ROOT_OPTIONS]
      }]
    };
  }
  constructor(livePreviewService) {
    this.livePreviewService = livePreviewService;
  }
  /** @nocollapse */
  static {
    this.ɵfac = function ContentularModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || ContentularModule)(i0.ɵɵinject(LivePreviewService));
    };
  }
  /** @nocollapse */
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: ContentularModule
    });
  }
  /** @nocollapse */
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule, HttpClientModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ContentularModule, [{
    type: NgModule,
    args: [{
      declarations: [ContentularComponent, EditorDirective, EditorComponent],
      imports: [CommonModule, HttpClientModule],
      exports: [ContentularComponent, EditorDirective, EditorComponent]
    }]
  }], () => [{
    type: LivePreviewService
  }], null);
})();
class ContentularService {
  constructor(contentularConfig, platformId, httpHandler, rendererFactory) {
    this.contentularConfig = contentularConfig;
    this.platformId = platformId;
    this.httpHandler = httpHandler;
    this.rendererFactory = rendererFactory;
    this.cache$ = new ReplaySubject(1);
    this.currentCache$ = this.cache$.asObservable();
    this.cachedStories$ = this.cache$.asObservable().pipe(map(contentularCache => contentularCache.cacheFiles));
    this.loadedAllOnce = false;
    this.defaultCache = {
      cacheFiles: []
    };
    this.renderer2 = rendererFactory.createRenderer(null, null);
    this.http = new HttpClient(httpHandler);
    this.localStorageAvailable = this.checkForStorage();
    if (isPlatformServer(this.platformId)) {
      console.log('Storage available:', this.localStorageAvailable);
    }
    this.config = {
      apiUrl: 'https://app.contentular.io/api',
      ...contentularConfig
    };
    this.defaultRequestOptions = {
      ...this.config,
      cachingStrategy: this.config.cachingStrategy
    };
    this.setupInitialCache();
    if (this.config.persistentCache) {
      this.persistCache();
    }
    this.listenForContentUpdates();
  }
  findAndReplaceContent(contents, newContent) {
    return contents.map(content => {
      if (content._id === newContent._id) {
        const fieldKeys = Object.keys(newContent.fields);
        for (let i = 0; i < fieldKeys.length; i++) {
          if (!Array.isArray(newContent.fields[fieldKeys[i]])) {
            content.fields[fieldKeys[i]] = newContent.fields[fieldKeys[i]];
          }
        }
        return {
          ...content
        };
      }
      const fieldKeys = Object.keys(content.fields);
      for (let i = 0; i < fieldKeys.length; i++) {
        if (Array.isArray(content.fields[fieldKeys[i]])) {
          const replacedFieldContent = this.findAndReplaceContent(content.fields[fieldKeys[i]], newContent);
          if (replacedFieldContent !== content.fields[fieldKeys[i]]) {
            content.fields[fieldKeys[i]] = replacedFieldContent;
            // Return a copy to change the reference and fix *ngFor issues
            return {
              ...content
            };
          }
        }
      }
      return content;
    });
  }
  listenForContentUpdates() {
    let removeMessageEventListener;
    const messageEventListener = handler => {
      removeMessageEventListener = this.renderer2.listen('window', 'message', handler);
    };
    fromEventPattern(messageEventListener).subscribe(event => {
      if (event.data.type && event.data.type === 'contentUpdate') {
        this.cache$.pipe(take(1), map(cache => cache.cacheFiles)).subscribe(stories => {
          const contentsToUpdate = event.data.payload;
          contentsToUpdate.forEach(contentToUpdate => {
            const story = stories.find(story => story._id === contentToUpdate.story);
            if (story) {
              story.contents = this.findAndReplaceContent(story.contents, contentToUpdate);
              this.updateCache([story]);
            }
          });
        });
      }
    });
  }
  checkForStorage() {
    const test = 'test';
    try {
      localStorage.setItem(test, test);
      localStorage.removeItem(test);
      return true;
    } catch (e) {
      return false;
    }
  }
  getAll(options) {
    const requestOptions = {
      ...this.defaultRequestOptions,
      ...options
    };
    // console.log('current-strategy', requestOptions.cachingStrategy);
    let responseStream;
    switch (requestOptions.cachingStrategy) {
      case "network-only" /* ContentularCachingStrategy.networkOnly */:
        responseStream = this.loadAllNetworkOnly(requestOptions);
        break;
      case "network-first" /* ContentularCachingStrategy.networkFirst */:
        responseStream = this.loadAllNetworkFirst(requestOptions);
        break;
      case "cache-first" /* ContentularCachingStrategy.cacheFirst */:
        responseStream = this.loadAllCacheFirst(requestOptions);
        break;
    }
    const stream = responseStream.pipe(switchMap(stories => this.cachedStories$.pipe(map(cachedStories => cachedStories.filter(cachedStory => stories.some(story => story._id === cachedStory._id))))), shareReplay(1));
    stream.subscribe();
    return stream;
  }
  loadAllNetworkOnly(requestOptions) {
    const apiCall = this.createApiCall(requestOptions);
    return apiCall.pipe(tap(stories => {
      this.loadedAllOnce = true;
      this.updateCache(stories);
    }), catchError(err => {
      // console.log('cant get stories');
      throw err;
    }));
  }
  loadAllNetworkFirst(requestOptions) {
    const apiCall = this.createApiCall(requestOptions);
    return apiCall.pipe(tap(stories => {
      this.loadedAllOnce = true;
      this.updateCache(stories);
    }), catchError(err => {
      // console.log('cant get stories');
      // console.log('return cache');
      if (err.status === 404) {
        this.removeAllFromCache();
      }
      return this.currentCache$.pipe(take(1), map(cache => cache.cacheFiles), map(cachedStories => {
        if (cachedStories.length === 0) {
          throw {
            msg: 'No Data available'
          };
        }
        return cachedStories;
      }));
    }));
  }
  loadAllCacheFirst(requestOptions) {
    const apiCall = this.createApiCall(requestOptions);
    return this.currentCache$.pipe(take(1), switchMap(cache => {
      if (cache.cacheFiles.length === 0 || !this.loadedAllOnce) {
        // console.log('cache empty, try to load');
        return apiCall.pipe(tap(stories => {
          this.loadedAllOnce = true;
          this.updateCache(stories);
        }), catchError(err => {
          // console.log('cant get stories');
          if (err.status === 404) {
            this.removeAllFromCache();
          }
          throw {
            msg: 'No Data available',
            err
          };
        }));
      }
      return of(cache.cacheFiles);
    }));
  }
  findBySlug(slug, options) {
    const requestOptions = {
      ...this.defaultRequestOptions,
      ...options
    };
    // console.log('current-strategy', requestOptions.cachingStrategy);
    let responseStream;
    switch (requestOptions.cachingStrategy) {
      case "network-only" /* ContentularCachingStrategy.networkOnly */:
        responseStream = this.loadBySlugNetworkOnly(requestOptions, slug);
        break;
      case "network-first" /* ContentularCachingStrategy.networkFirst */:
        responseStream = this.loadBySlugNetworkFirst(requestOptions, slug);
        break;
      case "cache-first" /* ContentularCachingStrategy.cacheFirst */:
        responseStream = this.loadBySlugCacheFirst(requestOptions, slug);
        break;
    }
    return responseStream.pipe(switchMap(stories => this.cachedStories$.pipe(map(cachedStories => cachedStories.filter(cachedStory => stories.some(story => story._id === cachedStory._id))))));
  }
  loadBySlugNetworkOnly(requestOptions, slug) {
    const apiCall = this.createApiCall(requestOptions, slug);
    return apiCall.pipe(tap(stories => this.updateCache(stories)), catchError(err => {
      // console.log('cant get stories with slug');
      throw err;
    }));
  }
  loadBySlugCacheFirst(requestOptions, slug) {
    const apiCall = this.createApiCall(requestOptions, slug);
    return this.cache$.pipe(take(1), map(cache => cache.cacheFiles), map(cache => cache.filter(story => story.slug === slug)), switchMap(cache => {
      if (cache.length === 0) {
        // console.log('cache empty, try to load');
        return apiCall.pipe(tap(loadedStories => {
          this.updateCache(loadedStories);
        }), catchError(err => {
          if (err.status === 404) {
            this.removeFromCache(slug);
          }
          // console.log('cant get stories');
          throw {
            msg: 'No Data available',
            err
          };
        }));
      }
      return of(cache);
    }));
  }
  loadBySlugNetworkFirst(requestOptions, slug) {
    const apiCall = this.createApiCall(requestOptions, slug);
    return apiCall.pipe(tap(stories => {
      this.updateCache(stories);
    }), catchError(err => {
      // console.log('cant get stories with slug');
      // console.log('return cache');
      if (err.status === 404) {
        this.removeFromCache(slug);
      }
      return this.cache$.pipe(take(1), map(cache => cache.cacheFiles), map(cache => cache.filter(story => story.slug === slug)), map(cacheMap => {
        if (cacheMap.length === 0) {
          throw {
            msg: 'No Data available'
          };
        }
        return cacheMap;
      }));
    }));
  }
  setupInitialCache() {
    let existingCache;
    if (this.localStorageAvailable) {
      existingCache = localStorage.getItem(this.config.apiKey);
    }
    if (existingCache && this.localStorageAvailable && this.config.persistentCache) {
      const cache = {
        ...this.defaultCache,
        ...JSON.parse(existingCache)
      };
      this.cache$.next(cache);
    } else {
      if (this.localStorageAvailable) {
        localStorage.removeItem(this.config.apiKey);
      }
      this.cache$.next(this.defaultCache);
    }
  }
  persistCache() {
    this.cache$.subscribe(cache => {
      if (this.localStorageAvailable) {
        localStorage.setItem(this.config.apiKey, JSON.stringify(cache));
      }
    });
  }
  updateCache(stories) {
    // console.log('update Cache');
    this.cache$.pipe(take(1)).subscribe(currentCache => {
      const cachedFiles = [...currentCache.cacheFiles];
      stories.forEach(story => {
        const cacheIndex = cachedFiles.findIndex(cache => cache._id === story._id);
        if (cacheIndex > -1) {
          cachedFiles[cacheIndex] = story;
        } else {
          cachedFiles.push(story);
        }
      });
      const updatedCache = {
        cacheFiles: cachedFiles
      };
      this.cache$.next(updatedCache);
    });
  }
  removeFromCache(slug) {
    // console.log('remove from Cache');
    this.cache$.pipe(take(1)).subscribe(currentCache => {
      const cachedFiles = [...currentCache.cacheFiles];
      const updatedCachedFiles = cachedFiles.filter(story => story.slug !== slug);
      const updatedCache = {
        cacheFiles: updatedCachedFiles
      };
      this.cache$.next(updatedCache);
    });
  }
  removeAllFromCache() {
    // console.log('remove all from Cache');
    const updatedCache = {
      cacheFiles: []
    };
    this.cache$.next(updatedCache);
  }
  createApiCall(requestOptions, slug) {
    const baseUrl = `${requestOptions.apiUrl}/stories/frontend`;
    const url = slug ? baseUrl + '?slug=' + slug : baseUrl;
    // const headers = ne({'x-api-key': this.config.apiKey});
    const options = {
      params: {
        'api_key': requestOptions.apiKey || this.config.apiKey
      }
    };
    return this.http.get(url, options);
  }
  /** @nocollapse */
  static {
    this.ɵfac = function ContentularService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || ContentularService)(i0.ɵɵinject(CONTENTULAR_CONFIG), i0.ɵɵinject(PLATFORM_ID), i0.ɵɵinject(i1$1.HttpBackend), i0.ɵɵinject(i0.RendererFactory2));
    };
  }
  /** @nocollapse */
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: ContentularService,
      factory: ContentularService.ɵfac,
      providedIn: ContentularModule
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ContentularService, [{
    type: Injectable,
    args: [{
      providedIn: ContentularModule
    }]
  }], () => [{
    type: undefined,
    decorators: [{
      type: Inject,
      args: [CONTENTULAR_CONFIG]
    }]
  }, {
    type: undefined,
    decorators: [{
      type: Inject,
      args: [PLATFORM_ID]
    }]
  }, {
    type: i1$1.HttpBackend
  }, {
    type: i0.RendererFactory2
  }], null);
})();
class ContentularDirective {
  set content(content) {
    if (!content) {
      return;
    }
    if (typeof this.config.componentMap[content.type] === 'undefined') {
      console.info(content.type, ' not found');
      return;
    }
    this.viewContainerRef.clear();
    this.componentRef = this.viewContainerRef.createComponent(this.config.componentMap[content.type]);
    if (typeof this.componentRef.instance.content?.set === 'function') {
      this.componentRef.instance.content.set(content);
    } else {
      this.componentRef.instance.content = content;
    }
    this.cdr.markForCheck();
  }
  constructor(config, viewContainerRef, cdr) {
    this.config = config;
    this.viewContainerRef = viewContainerRef;
    this.cdr = cdr;
  }
  /** @nocollapse */
  static {
    this.ɵfac = function ContentularDirective_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || ContentularDirective)(i0.ɵɵdirectiveInject(CONTENTULAR_CONFIG), i0.ɵɵdirectiveInject(i0.ViewContainerRef), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef));
    };
  }
  /** @nocollapse */
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: ContentularDirective,
      selectors: [["", "contentular", ""]],
      inputs: {
        content: "content"
      }
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ContentularDirective, [{
    type: Directive,
    args: [{
      selector: '[contentular]',
      standalone: true
    }]
  }], () => [{
    type: undefined,
    decorators: [{
      type: Inject,
      args: [CONTENTULAR_CONFIG]
    }]
  }, {
    type: i0.ViewContainerRef
  }, {
    type: i0.ChangeDetectorRef
  }], {
    content: [{
      type: Input
    }]
  });
})();
'use strict';
const valuesMap = new Map();
class LocalStorage {
  getItem(key) {
    const stringKey = String(key);
    if (valuesMap.has(key)) {
      return String(valuesMap.get(stringKey));
    }
    return null;
  }
  setItem(key, val) {
    valuesMap.set(String(key), String(val));
  }
  removeItem(key) {
    valuesMap.delete(key);
  }
  clear() {
    valuesMap.clear();
  }
  key(i) {
    if (arguments.length === 0) {
      throw new TypeError("Failed to execute 'key' on 'Storage': 1 argument required, but only 0 present."); // this is a TypeError implemented on Chrome, Firefox throws Not enough arguments to Storage.key.
    }
    var arr = Array.from(valuesMap.keys());
    return arr[i];
  }
  get length() {
    return valuesMap.size;
  }
}
const instance = new LocalStorage();
const LocalStoragePolyfill = new Proxy(instance, {
  set: function (obj, prop, value) {
    if (LocalStorage.prototype.hasOwnProperty(prop)) {
      // @ts-ignore
      instance[prop] = value;
    } else {
      instance.setItem(prop, value);
    }
    return true;
  },
  get: function (target, name) {
    if (LocalStorage.prototype.hasOwnProperty(name)) {
      // @ts-ignore
      return instance[name];
    }
    if (valuesMap.has(name)) {
      return instance.getItem(name);
    }
  }
});

/*
 * Public API Surface of contentular
 */

/**
 * Generated bundle index. Do not edit.
 */

export { CONTENTULAR_CONFIG, ContentularComponent, ContentularDirective, ContentularModule, ContentularService, EditorComponent, EditorDirective, LocalStoragePolyfill, contentularConfigFactory };
